@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  text-align: center;
  /* font-family: 'Roboto', sans-serif !important; */
  font-family: 'Poppins', sans-serif !important;
  color: #444;
}
body, button, input, form, label, h5, h4, h3, h2, h1{
  font-family: 'Poppins', sans-serif !important;
  color: #444;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.nav-logo {
    width:200;
    height:100; 
    background:'lightgrey'; 
    border-radius:10; 
    position:'absolute';
    z-index:'1';
}

.accordion-button {
  background-color: white !important;
}

.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #e9ecef;
  border-left-width: .40rem;
  border-radius: .25rem;
}

.callout-active {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #da7d13;
  border-left-width: .40rem;
  border-radius: .25rem;
}

.shaked:hover{
   /* Start the shake animation and make the animation last for 0.5 seconds */
   animation: shake 0.7s linear;

   /* When the animation is finished, start again */
   animation-iteration-count: infinite;
}

.notif:hover{
  background-color: whitesmoke;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -1px) rotate(-3deg); }
  20% { transform: translate(-2px, 0px) rotate(2deg); }
  30% { transform: translate(1px, 0px) rotate(1deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-1px, 0px) rotate(2deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.zoom{
  transition: transform .2s;
}

.zoom:hover{
  transform: scale(1.1);
}

.c-color:hover{
  color: #da7d13;
}

.pointer{
  cursor: pointer;
}

.callout:hover{
  border-color: #cccfd2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text-truncate-2 {
  -webkit-line-clamp: 2;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  text-decoration: none;
}

a {
  text-decoration :none;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes border-pulse {
  0%   { border-color: rgb(253, 205, 169); }
  50% { border-color: rgb(253, 156, 111); }
  100%   { border-color: rgb(251, 106, 66); }
}

@keyframes border-pulse-color {
  0%   { color: #dfdfdf;}
  100%   { color: #d33030;}
}

.timeline ul li{
  list-style-type: none;
  position: relative;
  width: 1px;
  margin: 0 auto;
  padding-bottom: 30px;
  margin-left: 0;
  background: repeating-linear-gradient(
    180deg,
    #fff,
    #fff 1px,
    #9c9c9c 2px,
    #bfbfbf 5px
  )
}

.timeline ul li::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 15px;
  height: 14px;
  border-radius: 50%;
  background: #9c9c9c;
  border: solid 4px #dfdfdf;
}

.timeline ul li:first-child:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 19px;
  height: 18px;
  border-radius: 50%;
  background: #f64013;
  border: solid 5px #f3b31c;
  animation: border-pulse 2s infinite;
}

.border-pulse-color {
  animation: border-pulse-color 2s infinite;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 40em;
  margin-left: 15px;
}

.product-card{
  cursor: pointer;
  font-size: 0.8rem;
}


.product-card:hover{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact-info{
  background: whitesmoke;
  width: 50%;
  border-radius: 15px 0 0 15px;
  text-align: start;
}

.contact-info-child{
  background: black;
  color: whitesmoke;
  width:100%;
  padding: 5px 5px;
  border-radius: 0px 0px 15px 15px;
  text-align: center;
}

.contact-container {
  display: flex;
  flex-direction : row; 
  flex-wrap: nowrap; 
  height: 500px;
}

.contact-container-child {
  display: flex;
  flex-direction : column; 
  flex-wrap: nowrap; 
  height: 270px;
}

.contact-map{
  width: 50%; 
  border-radius: 10px 0 0 10px;
}

.contact-map-map{
  display: block;
  border-radius: 0 15px 15px 0;
}

.contact-map-map-child{
  display: block;
  border-radius: 15px 15px 0px 0px;
}

@media only screen and (max-width: 600px) {
  .contact-info{
    background: whitesmoke;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    padding-bottom: 50px;
  }
  
  .contact-container {
    display: flex;
    flex-direction : column; 
    flex-wrap: nowrap; 
    height: 500px;
  }
  
  .contact-map{
    width: 100%; 
  }

  .contact-map-map{
    display: block;
     border-radius: 0px 0px 15px 15px;
     height: 350px;

  }
}


@keyframes wave {
  0% {
    transform: translateY(0);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  25% {
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  }
  50% {
    transform: translateY(0);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  75% {
    transform: translateY(5px);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: translateY(0);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

.label-wrapper {
  display: inline-block;
  animation: wave 2s ease-in-out infinite;
}

/* Gaya kustom untuk membuat warna title dropdown konsisten */
.navbar-nav .nav-link,
.navbar-nav .nav-item .nav-link {
  color: #000; /* Sesuaikan dengan warna yang diinginkan */
}

/* Gaya kustom untuk membuat warna title dropdown bersarang konsisten */
.navbar-nav .nav-item .nav-item .nav-link {
  color: #000; /* Sesuaikan dengan warna yang diinginkan */
}

/* Atur margin untuk item dropdown */
.navbar-nav .nav-item {
  margin-left: 8px;
}

/* Atur margin untuk item dropdown bersarang */
.navbar-nav .nav-item .nav-item {
  margin-left: 8px; 
}

/* Sembunyikan ikon panah untuk drop="down" */
.navbar-nav .nav-item > .nav-link.dropdown-toggle:not(.dropend)::after {
  display: none !important;
}

/* Tampilkan ikon panah hanya untuk drop="end" */
.navbar-nav .nav-item.dropend > .nav-link.dropdown-toggle::after {
  display: inline !important;
  content: ""; /* Anda mungkin perlu menambahkan gambar panah di sini */
  margin-top: 0.5em;
  float: inline-end;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.floating-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-btn {
  position: fixed;
  bottom: 20px; 
  left: 20px; 
  width: 100px;
  height: 100px;
  border-radius: 10%;
  /* background-color: #6a0c0c; */
  color: #fff;
  font-size: 12px;
  border: none;
  cursor: pointer;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  outline: none;
  animation: bounce 2s infinite alternate; /* Animasi bouncing */
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Sesuaikan dengan tinggi bouncing */
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.floating-btn:hover {
  transform: scale(1.1);
  /* box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); */
}

.zoomin-button {
  cursor: pointer;
  animation: zoom 1s infinite alternate;
}
